import $ from 'jquery';

export default function scrollToTop() {
  
  function init() {
    $(document).scroll(function() {
      if ($(document).scrollTop() >= 300) {
        $('#scroll-to-top').addClass('visible');
      } else {
        $('#scroll-to-top').removeClass('visible');
      }
    });
    
    $('#scroll-to-top').on('click', function(e) {
      e.preventDefault();
      
      $('html,body').animate({
        scrollTop: 0
      },'slow');
    });
  }
  
  $( document ).ready(function() {
    if($('#scroll-to-top').length > 0) {
      init();
    }
  });
  
}