import $ from 'jquery';

export default function acknowledgmentFilter() {

  function init() {
    $('.acknowledgment-filter').on('click', '.acknowledgment-filter__link', function() {
      let filter = $(this).data('filter');

      $('.acknowledgment-filter__item').removeClass('acknowledgment-filter__item--active');
      $(this).parent('.acknowledgment-filter__item').addClass('acknowledgment-filter__item--active');

      if(filter === 'all') {
        $('.acknowledgment-list > div[data-group]').show();
      } else {
        $('.acknowledgment-list > div[data-group]').hide();
        $('.acknowledgment-list').find('div[data-group="'+filter+'"]').show();
      }
    });
  }

  $( document ).ready(function() {
    if ($('.acknowledgment-filter').length > 0){
      init();
    }
  });
}