import $ from 'jquery';

export default function typeFilterSearch() {
  
  function init() {
    $('.intranet-search .intranet-search__type').on('click', function() {
      //e.preventDefault();
      $('.intranet-notification').removeClass('open');
      $('.intranet-hamburger').removeClass('open');

      if(!$(this).parents('.intranet-search__choose').hasClass('open')) {
        $(this).parents('.intranet-search__choose').addClass('open');
      } else {
        $(this).parents('.intranet-search__choose').removeClass('open');
      }
    });
  }

  function filter() {
    $('.intranet-search .intranet-search__filter-label').on('click', function() {
      let textLabel = $(this).text();
      let actionForm = $(this).data('action');
      const id = $(this).children('input').attr('id');
      
      $('.intranet-search .intranet-search__type').html($.trim(textLabel));
      $('.intranet-search__choose').removeClass('open');
      $('.intranet-search__box .intranet-search__link').attr('href', actionForm);
      $('.intranet-search .intranet-search__form').attr('action', actionForm);
      if (id === 'all'){
        $('.intranet-search  .intranet-search__box').removeClass('d-none');
      } else {
        $('.intranet-search  .intranet-search__box.intranet-search__box--'+id).removeClass('d-none');
        $('.intranet-search  .intranet-search__box:not(.intranet-search__link):not(.intranet-search__box--'+id+')').addClass('d-none');
      }
    });
  }

  function showResults() {
    $('.intranet-search .intranet-search__text').on('keyup paste input', function(e) {
      if (e.target.value && e.target.value.length >= 3) {
        if(!$(this).parents('.intranet-search').hasClass('open')) {
          $(this).parents('.intranet-search').addClass('open');
        } 
      } else {
        if($(this).parents('.intranet-search').hasClass('open')) {
          $(this).parents('.intranet-search').removeClass('open');
        }
      }
    }).on('focus', function(e) {
      if (e.target.value && e.target.value.length >= 3) {
        if(!$(this).parents('.intranet-search').hasClass('open')) {
          $(this).parents('.intranet-search').addClass('open');
        } 
      }
    }).on('blur', function() {
      var input = $(this);
      setTimeout(function() {
        if(input.parents('.intranet-search').hasClass('open')) {
          input.parents('.intranet-search').removeClass('open');
        }
      }, 300);
    });
  }

  
  $( document ).ready(function() {
    if($('.intranet-search .intranet-search__type').length > 0) {
      init();
    }

    if($('.intranet-search .intranet-search__filter').length > 0) {
      filter();
    }

    if($('.intranet-search .intranet-search__text').length > 0) {
      showResults();
    }
  });
}