import $ from 'jquery';
import 'resize-sensor';
import 'sticky-sidebar';

export default function sidebar() {
  
  function init() {
    var stickySidebar = new StickySidebar('#sidebar', {
			topSpacing: 20,
			bottomSpacing: 20,
			containerSelector: '.container',
			innerWrapperSelector: '.sidebar__inner'
		});
  }

  function sidebarFilterFavorite() {
    $('.box-favorite .box-favorite__select select').on('change', function(e) {
      e.preventDefault();
      let filterId = this.value;
      $('.box-favorite .filter-results').addClass('d-none');
      //attivo la voce seleziona e visualizzo il contenitore corretto
      $(this).addClass('active');
      $('#'+filterId).removeClass('d-none');
    });
  }

  function sidebarFilterCorsi() {
    $('.box-elearning .box-elearning__select select').on('change', function(e) {
      e.preventDefault();
      let filterId = this.value;
      $('.box-elearning .filter-results').addClass('d-none');
      //attivo la voce seleziona e visualizzo il contenitore corretto
      $(this).addClass('active');
      $('#'+filterId).removeClass('d-none');
    });
  }
  
  $( document ).ready(function() {
    if($('#sidebar').length > 0) {
			init();
    }

    if ($('.box-favorite .box-favorite__select').length > 0) {
      sidebarFilterFavorite();
    }

    if($('.box-elearning .box-elearning__select').length > 0) {
      sidebarFilterCorsi();
    }
  });
  
}