import $ from 'jquery';

export default function uploadFile() {

  function init() {
    $('.upload-file .upload-file__delete-btn').on('click', function (e){
      e.preventDefault();

      let inputfilestyle = $(this).parents('.upload-file').find('.filestyle');
      $(this).parents('.upload-file__delete').addClass('d-none');
      $(this).parents('.upload-file').find('.upload-file__img').addClass('d-none');

      if (inputfilestyle.length > 0) {
        inputfilestyle.filestyle('disabled', false);
      }
      //aggiunta gestione eliminazione cv
      if (process.env.LIFERAY_ENV) {
        var inputId = $(this).data("file");
        $('#'+inputId).val("1");
      }
    });

    if (process.env.LIFERAY_ENV) {
      $("#cv").on('change', function(){
        $("#deletecv").val("0");
      });
      $("#avatar").on('change', function(){
        $("#deleteavatar").val("0");
      });
    }
    
    $("#avatar").on('change', function (e) {
      if(this.files[0].size > 500000){
        alert("L'immagine caricata ha una peso troppo grande. Il limite massimo è di 50Kb.");
        this.value = "";
      };
    });
  }

  $( document ).ready(function() {
    
    if ($('.upload-file').length > 0) {
      init();
    }

  });
}