import $ from 'jquery';

export default function accordion() {

  function init() {
    // clickOut
    $(document).on("click", function (event) {
      if (!$(event.target).closest(".intranet-sort").length) {
        $(".intranet-sort").removeClass("open");
      }
    });
    $(document).on("click", function (event) {
      if (!$(event.target).closest(".intranet-pagination__menu").length) {
        $(".intranet-pagination__menu").removeClass("open");
      }
    });

    $(document).on('click', '.js-accordion__toggle', function() {
      if(!$(this).parents('.js-accordion').hasClass('open')) {
        if(!$(this).hasClass('faccette__header') && !$(this).hasClass('box-faq__title') &&
           !$(this).hasClass('intranet-pagination__btn') && !$(this).hasClass('intranet-user__btn') &&
           !$(this).hasClass('intranet-sort__btn') && !$(this).hasClass('box-iscrizioni__title'))
        {
          //chiudo tutti gli altri accordion tranne: faccette, faq, paginazione ricerca e user button header)
          $('.js-accordion').removeClass('open');
        }
        $(this).parents('.js-accordion').addClass('open');
      } else {
        $(this).parents('.js-accordion').removeClass('open');
      }
    });
  }
  
  $( document ).ready(function() {
    if($('.js-accordion .js-accordion__toggle').length > 0) {
      init();
    
      if (process.env.LIFERAY_ENV) {  
        Liferay.on('SPAReady',function(){
          Liferay.SPA.app.on('endNavigate', function() {
            $(document).off('click', '.js-accordion__toggle');

            init();
          })
        })
      }

    }
  });  
}