import $ from 'jquery';

export default function userBtn() {
  
  function init() {
    // clickOut
    $(document).on("click", function (event) {
      if (!$(event.target).closest(".intranet-user").length) {
        $(".intranet-user").removeClass("open");
      }
    });

    $('.intranet-user .intranet-user__btn').on('click', function(e) {
      $('.intranet-notification').removeClass('open');
      $('.intranet-hamburger').removeClass('open');

      if(!$(this).parents('.intranet-user').hasClass('open')) {
        $(this).parents('.intranet-user').addClass('open');
      } else {
        $(this).parents('.intranet-user').removeClass('open');
      }
    });
  }

  
  $( document ).ready(function() {
    if($('.intranet-user .intranet-user__btn').length > 0) {
      init();
    }
  });
}