import $ from 'jquery';

export default function toggleSearchFilters() {

  function init(){
    let widthDevice = $(window).width();

    //chiudo filtri faccette
    function closeFilter(){
      $('.intranet-search-grid__toggle-filters').removeClass('intranet-search-grid__toggle-filters--open');
      $('.intranet-search-grid__body').removeClass('intranet-search-grid__body--filters');
    }
    if (widthDevice < 768) {
      closeFilter();
    }
    $(window).on('resize', function() {
      let widthDevice = $(window).width();
      if (widthDevice < 768) {
        closeFilter();
      }
    });

    //toggle filtri faccette
    $('.intranet-search-grid .intranet-search-grid__toggle-filters').on('click', function(e) {
      e.preventDefault();

      $(this).toggleClass('intranet-search-grid__toggle-filters--open');
      $('.intranet-search-grid__body').toggleClass('intranet-search-grid__body--filters');
    });
  }

  $( document ).ready(function() {
    if ($('.intranet-search-grid').length > 0) {
      init();
    }
  });
}