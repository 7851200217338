import $ from 'jquery';

export default function indiceAZ() {

  function init() {
    $('.intranet-indice .intranet-indice__list .intranet-indice__item[data-evidenza="false"]').addClass('d-none');
    $('.intranet-indice .intranet-search-grid__letters button').on('click', function() {
      const letter = $(this).data('letter');

      if(!$(this).hasClass('active')) {
        $('.intranet-indice .intranet-search-grid__letters button').removeClass('active');
        $(this).addClass('active');
        $('.intranet-indice .intranet-indice__title').html('<span class="text-uppercase">'+letter+'</span>');
        $('.intranet-indice .intranet-indice__list .intranet-indice__item').removeClass('d-none');
        $('.intranet-indice .intranet-indice__list .intranet-indice__item:not([data-letter="'+letter+'"])').addClass('d-none');
      } else {
        $('.intranet-indice .intranet-search-grid__letters button').removeClass('active');

        let label = 'In evidenza';
        
        if (process.env.LIFERAY_ENV) {
          label =  $('.intranet-indice .intranet-indice__title').data('key');
        }

        $('.intranet-indice .intranet-indice__title').html(label);
        $('.intranet-indice .intranet-indice__list .intranet-indice__item').removeClass('d-none');
        $('.intranet-indice .intranet-indice__list .intranet-indice__item[data-evidenza="false"]').addClass('d-none');
      }
      
    });
  }

  $( document ).ready(function() {
    
    if ($('.intranet-indice').length > 0){
      init();
    }
  });
}