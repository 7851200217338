import $ from 'jquery';

export default function anchorFluid() {

  function init() {
    $('.main-wrapper a.js-anchor-fluid[href^="#"]').on('click', function() {
			if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
				var $target = $(this.hash);
				$target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
				if ($target.length) {
					var targetOffset = $target.offset().top;

					//calcolo offset se è presente la barra di liferay
					if (process.env.LIFERAY_ENV) {
						var controlMenu = $('#ControlMenu');
						if(controlMenu.length > 0){
							var bannerHeight = controlMenu.height()
							targetOffset = targetOffset - bannerHeight;
						}
					}
					$('html,body').animate({scrollTop: targetOffset} , 1000);
					return false;
				}
			}
		});
  }

  $( document ).ready(function() {
    if($('.main-wrapper a[href^="#"]').length > 0) {
      init();
    }
  });
}
