import $ from 'jquery';

export default function subscribe() {
  
  function init() {
    $('.intranet-subscribe .intranet-subscribe__link').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();

      if (!process.env.LIFERAY_ENV){
        $(this).children('.ico-svg, .intranet-subscribe__label').toggleClass('d-none');
      } else {

        let element = $(this);

        Liferay.Service(
          '/usr_msg.usrmsgconf/subscription-toggle',
          {
            companyId: Liferay.ThemeDisplay.getCompanyId(),
            groupId: Liferay.ThemeDisplay.getScopeGroupId(),
            userId: Liferay.ThemeDisplay.getUserId(),
            subTypeId: $(".article-detail__category").data("subtypeid"),
            vocabularyId: $(".article-detail__category").data("vocabularyid"),
            assetCategoryId: $(".article-detail__category").data("assetcategoryid"),
            channelId: 1,
          },
          function(obj) {
            //console.log(obj);
			element.toggleClass('subscribed'); 
            element.children('.ico-svg, .intranet-subscribe__label').toggleClass('d-none');              
          }
        );
      } 
    });
  }

  $( document ).ready(function() {
    
    if ($('.intranet-subscribe .intranet-subscribe__link').length > 0) {
      init();
    }

  });
}