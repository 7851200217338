import $ from 'jquery';

export default function mobileAccordion() {
  
  function init() {
    $('.box-portlet .box-portlet__toggle').on('click', function(e) {
      if(!$(this).parents('.box-portlet').hasClass('open-md')) {
        $(this).parents('.box-portlet').addClass('open-md');
      } else {
        $(this).parents('.box-portlet').removeClass('open-md');
      }
    });
  }
  
  $( document ).ready(function() {
    if($('.box-portlet .box-portlet__toggle').length > 0) {
      init();
    }
  });
}