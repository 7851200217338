import $ from 'jquery';
// https://bootstrap-tagsinput.github.io/bootstrap-tagsinput/examples/
import 'bootstrap-tagsinput';

export default function tagsInInput() {
  
  function init() {
    $('.tagsinput').tagsinput({
      trimValue: true,
      allowDuplicates: true
    });
  }
  
  $( document ).ready(function() {
    if($('.tagsinput').length > 0) {
      init();
    }
  });
}