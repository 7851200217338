import $ from 'jquery';

export default function hamburger() {
  
  function init() {
    $('.intranet-hamburger').on('click', function() {
      
      $('.intranet-user').removeClass('open');
      $('.intranet-notification').removeClass('open');
      $('.intranet-search__choose').removeClass('open');

      if(!$(this).hasClass('open')) {
        $('html').addClass('block-scroll');
        $(this).addClass('open');
        $('.intranet-navigation').addClass('open');
      } else {
        $('html').removeClass('block-scroll');
        $(this).removeClass('open');
        $('.intranet-navigation, .intranet-navigation .intranet-navigation__item').removeClass('open');
      }
    });
  }
  
  $( document ).ready(function() {
    if($('.intranet-hamburger').length > 0) {
      init();
    }
  });
  
}