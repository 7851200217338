import $ from 'jquery';
import 'slick-carousel';

export default function slider() {

  function sliderAvvisi() {
    $('.slider-avvisi').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 3,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 2,
          }
        },{
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }

  function sliderProdotti() {
    $('.slider-prodotti').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 3,
          }
        },{
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }

  function sliderCorsi() {
    $('.slider-corsi').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }

  function sliderHowto() {
    $('.slider-how-to').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }

  function sliderHowtoLastInsert() {
    $('.slider-how-to-last-insert').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }

  function sliderHowtoMoreView() {
    $('.slider-how-to-more-view').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }

  function sliderHowtoInteresting() {
    $('.slider-how-to-interesting').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }

  function sliderMediaGallery() {
    $('.slider-media-gallery').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }

  function sliderUtilityLinks() {
    $('.slider-utility-link').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 8,
      slidesToScroll: 8,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 6,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }
      ]
    });
  }

  function sliderUtilityLinksSidebar() {
    $('.slider-utility-link-sidebar').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1
    });
  }

  function sliderAppsSidebar() {
    $('.slider-apps-sidebar').slick({
      arrows: false,
      dots: true,
      vertical: true,
      verticalSwiping: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 5,
      slidesToScroll: 5
    });
  }

  function sliderFavoriteHero() {
    $('.slider-favorite-sidebar').slick({
      arrows: false,
      dots: true,
      vertical: true,
      verticalSwiping: true,
      rows: 0,
      infinite: false,
      autoplay: false,
      slidesToShow: 5,
      slidesToScroll: 5
    });

    $('body').on('click','.btn-filter-favorite',function() {
      filter(this);
    });

    const filter = (element) => {
      let target = element.dataset.filtertarget;
      resetFilterButtons();
      setFilterButtonActive(target);
      $('.slider-favorite-sidebar').slick('slickUnfilter');
      if (target !== "ALL") {
        $('.slider-favorite-sidebar').slick('slickFilter', `.${target}`);
      }
    };
    const resetFilterButtons = () => {
      document.querySelectorAll(".btn-filter-favorite").forEach(filterbtn => {
        filterbtn.classList.remove("active");
      });
    };
    const setFilterButtonActive = (target) => {
      console.log(target);
      document.querySelector(`[data-filtertarget=${target}]`).classList.add("active");
    };
  }

  function sliderFavouriteSidebar() {
    $('.slider-favourite-sidebar').slick({
      arrows: false,
      dots: true,
      vertical: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 5,
      slidesToScroll: 5
    });
  }

  function sliderUtilityLinksHowTo() {
    $('.slider-utility-link-howto').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }
      ]
    });
  }

  function sliderApps() {
    $('.slider-apps').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }
      ]
    });
  }

  function sliderPersone() {
    $('.slider-persone').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }
      ]
    });
  }

  function sliderNews() {
    $('.slider-news').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 3,
          }
        },{
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }

  function sliderEventi() {
    $('.slider-eventi').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 3,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }

  function sliderEventiRelated() {
    $('.slider-eventi-related').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }
  
  function sliderStartList() {
    var settingsStartList = {
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 9999,
          settings: "unslick"
        },
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    };
    
    var slickSliderStartList =  $('.slider-start-list').slick(settingsStartList);

    $('.slider-start-list').slick(settingsStartList);

    $(window).on('resize', function() {
      if( $(window).width() < 1200 && !slickSliderStartList.hasClass('slick-initialized')) {
        $('.slider-start-list').slick(settingsStartList);
      }
    });
  }

  function sliderNewsDetail() {
    $('.slider-photogallery-detail').slick({
      arrows: true,
      dots: true,
      infinite: true,
      autoplay: false,
      slidesToShow: 1,
      adaptiveHeight: false,
    });
  }
  
  function sliderBacheca() {
    $('.slider-bacheca').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }

  function sliderDirezioni() {
    $('.slider-direzioni').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 3,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }

  function sliderCategorie() {
    $('.slider-categorie').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    });
  }

  $( document ).ready(function() {
    if($('.slider-news').length > 0) {
      sliderNews();
    }

    if($('.slider-eventi').length > 0) {
      sliderEventi();
    }

    if($('.slider-eventi-related').length > 0) {
      sliderEventiRelated();
    }

    if($('.slider-start-list').length > 0) {
      sliderStartList();
    }

    if($('.slider-utility-link').length > 0) {
      sliderUtilityLinks();
    }

    if($('.slider-utility-link-sidebar').length > 0) {
      sliderUtilityLinksSidebar();
    }

    if($('.slider-favourite-sidebar').length > 0) {
      sliderFavouriteSidebar();
    }

    if($('.slider-apps-sidebar').length > 0) {
      sliderAppsSidebar();
    }

    if($('.slider-favorite-sidebar').length > 0) {
      sliderFavoriteHero();
    }

    if($('.slider-utility-link-howto').length > 0) {
      sliderUtilityLinksHowTo();
    }

    if($('.slider-apps').length > 0) {
      sliderApps();
    }

    if($('.slider-persone').length > 0) {
      sliderPersone();
    }

    if($('.slider-media-gallery').length > 0) {
      sliderMediaGallery();
    }

    if($('.slider-how-to').length > 0) {
      sliderHowto();
    }

    if($('.slider-how-to-last-insert').length > 0) {
      sliderHowtoLastInsert();
    }

    if($('.slider-how-to-interesting').length > 0) {
      sliderHowtoInteresting();
    }

    if($('.slider-how-to-more-view').length > 0) {
      sliderHowtoMoreView();
    }

    if($('.slider-prodotti').length > 0) {
      sliderProdotti();
    }

    if($('.slider-corsi').length > 0) {
      sliderCorsi();
    }

    if($('.slider-avvisi').length > 0) {
      sliderAvvisi();
    }

    if($('.slider-photogallery-detail').length > 0) {
      sliderNewsDetail();
    }

    if($('.slider-bacheca').length > 0) {
      sliderBacheca();
    }

    if($('.slider-direzioni').length > 0) {
      sliderDirezioni();
    }

    if($('.slider-categorie').length > 0) {
      sliderCategorie();
    }

  });
}