import $ from 'jquery';
import 'jquery.searchable/dist/jquery.searchable-1.1.0.min.js';

export default function filterResults() {
  
  function searchStudent() {
    $('#box-person-searchable-container').searchable({
			searchField: '#box-person-search',
			selector: '.col-search-box-person',
			childSelector: '.searchable',
			show: function( elem ) {
				elem.show();
			},
			hide: function( elem ) {
				elem.hide();
			}
		});
  }
  
  $( document ).ready(function() {
    if($('#box-person-searchable-container').length > 0) {
			searchStudent();
    }
  });
  
}