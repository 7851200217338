import $ from 'jquery';

export default function othersTab() {
  
  function init() {
    $('.intranet-tab .intranet-tab__others-btn').on('click', function(e) {
      if(!$(this).parents('.intranet-tab__nav').hasClass('open')) {
        $(this).parents('.intranet-tab__nav').addClass('open');
      } else {
        $(this).parents('.intranet-tab__nav').removeClass('open');
      }
    });
  }
  
  $( document ).ready(function() {
    if($('.intranet-tab .intranet-tab__others-btn').length > 0) {
      init();
    }
  });
}