import $ from 'jquery';

export default function bacheca() {
  
  //Tipologia di bacheca per inserimento annuncio
  function bachecaType() {

    //apertura modale con form inserimento coerente alla pagina
    $(document).on('click', '.modal-insert-announcement', function () {
      var formType = $(this).data('type');
      
      $('#bacheca-type .radio__input').prop('checked', false);
      $('#bacheca-type #'+formType).prop('checked', true);

      $(this).parents('.modal-body').find('.form-bacheca').addClass('d-none');
      $(this).parents('.modal-body').find('#form-'+formType).removeClass('d-none');
    });

    //radio button scelta form inserimento
    $('#bacheca-type .radio__input').on('click', function(e) {
      let tipologia = $(this).attr('id');

      $(this).parents('.modal-body').find('.form-bacheca').addClass('d-none');
      $(this).parents('.modal-body').find('#form-'+tipologia).removeClass('d-none');
    });
  }

  //Tipologia di bacheca per inserimento annuncio
  function bachecaCategory(type, category) {
    $('#form-'+type).on('change', '#'+type+'-announcementCategory', function(){
      var selectedCat = $(this).val();
    });
  }

  //Edit card annuncio
  function bachecaEdit() {
    $('.intranet-card__actions .intranet-card__item').on('click', 'button', function () {
      var idModal = $(this).data('target');
      
      $(idModal).modal('show');
    });
  }
  
  $( document ).ready(function() {
    if($('#bacheca-type').length > 0) {
      bachecaType();
    }
    
    if($('.intranet-card__actions .intranet-card__item button').length > 0) {
      bachecaEdit();
    }
  });
}