import $ from 'jquery';

export default function navigationMenu() {
  
  function init() {
    if (!process.env.LIFERAY_ENV) {
      $('.intranet-navigation__list .intranet-navigation__item.intranet-navigation__item--has_sub .intranet-navigation__link').on('click', function(e) {
        if($(this).parents(".intranet-navigation__item").hasClass('open')) {
          $(this).parents(".intranet-navigation__item").removeClass('open');
          //rimuovo gli stili inline (z-index)
          $('.intranet-header').removeAttr('style');
          //blocksite
          $('html').removeClass('block-scroll');
          $('.block-site-content').removeClass('active');
        } else {
          $('.intranet-navigation__list .intranet-navigation__item').removeClass('open');
          $(this).parents(".intranet-navigation__item").addClass('open');
          //aumento lo z-index dell'header (1002 block-scroll)
          $('.intranet-header').css('z-index', 1003);
          //blocksite
          $('html').addClass('block-scroll');
          $('.block-site-content').addClass('active');
        }
      });
    }

    $('.intranet-navigation__list .intranet-navigation__item .intranet-navigation__toggle').on('click', function(e) {
      if($(this).parents(".intranet-navigation__item").hasClass('open')) {
        $(this).parents(".intranet-navigation__item").removeClass('open');
      } else {
        $('.intranet-navigation__list .intranet-navigation__item').removeClass('open');
        $(this).parents(".intranet-navigation__item").addClass('open');
      }
    });
    
    $('.intranet-navigation__list .intranet-navigation__item.intranet-navigation__item--has_sub > .intranet-navigation__link').on('click', function(e) {
      e.preventDefault();
    });

    $('.intranet-megamenu .intranet-megamenu__close').on('click', function(e) {
      $(this).parents('.intranet-navigation__item').removeClass('open');
      $('.intranet-header').removeAttr('style');
      //blocksite
      $('html').removeClass('block-scroll');
      $('.block-site-content').removeClass('active');
    });
  }

  
  $( document ).ready(function() {
    init();
  });
}