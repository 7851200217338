import './polyfill/currentScript'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import darkMode from './dark-mode'
import utils from './utils'
import accordion from './js-accordion'
import userBtn from './intranet-user'
import notificationBtn from './intranet-notification'
import othersTab from './intranet-tab'
import hamburger from './hamburger'
import slider from './slider'
import scrollToTop from './scrolltop'
import sidebar from './sidebar'
import typeFilterSearch from './intranet-search'
import lightbox from './lightbox'
import clickLink from './clickLink'
import navigationMenu from './intranet-navigation'
import filestyle from './filestyle'
import tagsInInput from './tagsinput'
import prototipo from './prototipo'
import boxNotification from './box-notification'
import checkAll from './checkAll'
import infinite from './infinitescroll'
import liferay from './liferay'
import maps from './intranet-map'
import anchorFluid from './intranet-anchor'
import bacheca from './intranet-bacheca'
import dropzone from './dropzone'
import createGroup from './gruppi-creazione'
import filterResults from './searchable'
import boxDocumenti from './box-documenti'
import autocomplete from './autocomplete'
import datepicker from './datepicker'
import mobileAccordion from './box-portlet'
import indiceAZ from './indice-az'
import toggleSearchFilters from './toggle-search-filters'
import subscribe from './subscribe'
import uploadFile from './upload-file'
import acknowledgmentFilter from './acknowledgment-filter'

const bundle_url = new URL(document.currentScript.src);
__webpack_public_path__ = bundle_url.pathname.substring(0, bundle_url.pathname.lastIndexOf("/")+1);

utils();
accordion();
userBtn();
othersTab();
hamburger();
slider();
scrollToTop();
typeFilterSearch();
lightbox();
clickLink();
navigationMenu();
filestyle();
tagsInInput();
anchorFluid();
bacheca();
filterResults();
boxDocumenti();
mobileAccordion();
indiceAZ();
toggleSearchFilters();
subscribe();
uploadFile();
acknowledgmentFilter();
sidebar();

if (!process.env.LIFERAY_ENV) {

	darkMode();
	checkAll();
	prototipo();
	autocomplete();
	notificationBtn();
	boxNotification();
	infinite();
	maps();
	dropzone();
	createGroup();
	datepicker();
}

if (process.env.LIFERAY_ENV) {
	liferay();
}
