import $ from 'jquery';
// https://markusslima.github.io/bootstrap-filestyle/options.html
import 'bootstrap-filestyle2';

export default function filestyle() {
  
  function init() {
    $(".filestyle").filestyle();
  }
  
  $( document ).ready(function() {
    if($('.filestyle').length > 0) {
      init();
    }
  });
}