import $ from 'jquery';

export default function liferay() {

    $(document).ready(function () {
        initFavourite("");
        initLike();
        initAcknowledgment();
        initBoard();
        initMenus();
        initDarkMode();
        initSearch();
        initHappyBirthday();

        window.initAjaxFragment = function () {
            initFavourite("");
            initLike();
            initAcknowledgment();
        }
    });

    //
    // Like
    //

    function initLike() {
        $(".like-icon").off('click').each(function () {
            $(this).on("click", function (e) {
                e.preventDefault();
                let classPK = $(this).data('classpkrating');
                let className = $(this).data('classnamerating');
                let userId = $(this).data('userid');
                let url = "/o/intranet8020/manager/rating/update?classPK=" + classPK + "&className=" + className + "&userId=" + userId;

                $.ajax({
                    url: url,
                    method: "GET",
                    dataType: "json",
                    cache: false,
                    async: false,

                    success: function (data) {
                        //TODO check json di ritorno

                        let likeSvgs = $('svg[data-svgclasspk=' + classPK + ']');
                        let likeNumbers = $('span[data-likeclasspk=' + classPK + ']');
                        likeSvgs.parent().toggleClass("active");
                        //likeSvgs.find("svg").toggleClass("ico-solid");
                        likeNumbers.text(data.ratingsEntryCount);
                    },
                    error: function (data) {
                        console.error("errore");
                    }
                });

                // matomo
                if (_paq) {
                    let el = $(this);
                    if (el.hasClass('active')) {
                        _paq.push(['trackEvent', 'Like', 'Add Like', el.data('title')]);
                    }
                }

            });
        });
    }

    //
    // Menu
    //

    function initMenus() {
        initDropdownMenu();
        initFatMenu();
    }

    var localCache = {};

    function initFatMenu() {
        $(".intranet-fat-menu.intranet-navigation__list .intranet-navigation__item.intranet-navigation__item--has_sub .intranet-navigation__link").each(function () {

            $(this).on("click", function (e) {
                e.preventDefault();

                if ($(this).parent(".open").length == 1) {
                    $(this).parent().removeClass('open');
                    //aumento lo z-index dell'header (1002 block-scroll)
                    $('.intranet-header').removeAttr('style');
                    //blocksite
                    $('html').removeClass('block-scroll');
                    $('.block-site-content').removeClass('active');
                } else {
                    $('.intranet-navigation__list .intranet-navigation__item').removeClass('open');
                    $(this).parent().addClass('open');
                    //aumento lo z-index dell'header (1002 block-scroll)
                    $('.intranet-header').css('z-index', 1003);
                    //blocksite
                    $('html').addClass('block-scroll');
                    $('.block-site-content').addClass('active');

                }

                if ($(this).parent(".open").length == 1) {

                    let plid = $(this).data("plid");
                    let urlPartials = $(this).data('url');
                    let url = urlPartials + "&detailPlid=" + plid;
                    let elementToReplace = $(this).parent().find('.htmlAjax');

                    let callAlreadyDone = localCache[url];
                    if (callAlreadyDone) {
                    } else {
                        $.ajax({
                            type: "POST",
                            url: url,
                            cache: true,
                            async: true,
                            dataType: "html",
                            success: function (data) {
                                elementToReplace.html(data);
                                localCache[url] = true;
                                initFavourite(".intranet-fat-menu")
                                //initAddOrRemoveFavouriteLayout(); //FIXME
                                //clickLink();  //Non portato, da checkare dopo i documenti avanzati
                            },
                            error: function (data) {
                                if (console) {
                                    console.error("errore");
                                }
                            }
                        });
                    }

                    /// for each su classe custom -> ajax
                    $(".dynamic-load").each(function () {

                        if ($(this).parents('.intranet-navigation__item.open').length == 0)
                            return;

                        let plid = $(this).data("plid");
                        let urlPartials = $(this).data('url');
                        let url = urlPartials + "&detailPlid=" + plid;
                        let elementToReplace = $(this);

                        let callAlreadyDone_ = localCache[url];
                        if (callAlreadyDone_) {
                        } else {
                            $.ajax({
                                type: "POST",
                                url: url,
                                cache: true,
                                async: true,
                                dataType: "html",
                                success: function (data) {
                                    elementToReplace.html(data);
                                    localCache[url] = true;
                                },
                                error: function (data) {
                                    if (console) {
                                        console.error("errore");
                                    }
                                }
                            });
                        }
                    });
                }
            });
        });
    }

    function initDropdownMenu() {
        $(".intranet-menu.intranet-navigation__list .intranet-navigation__item.intranet-navigation__item--has_sub .intranet-navigation__link").each(function () {

            $(this).on("click", function (e) {
                if ($(this).parent(".open").length == 1) {
                    $(this).parent().removeClass('open');
                    //aumento lo z-index dell'header (1002 block-scroll)
                    $('.intranet-header').removeAttr('style');
                    //blocksite
                    $('html').removeClass('block-scroll');
                    $('.block-site-content').removeClass('active');
                } else {
                    $('.intranet-navigation__list .intranet-navigation__item').removeClass('open');
                    $(this).parent().addClass('open');
                    //aumento lo z-index dell'header (1002 block-scroll)
                    $('.intranet-header').css('z-index', 1003);
                    //blocksite
                    $('html').addClass('block-scroll');
                    $('.block-site-content').addClass('active');
                }
            });
        });
    }

    //
    // Search
    //

    function initSearch() {
        viewHideFacetTerm();
        viewFacet();
        searchBarDeleteParameter();
        viewAll();
    }

    function viewHideFacetTerm() {
        $('.js-viewall').on('click', function (e) {
            e.preventDefault();
            var elemToShow = $(this).closest(".js-view-container").find("li.elem");
            $(this).addClass("d-none");
            $(this).parent("div").find(".js-viewless").removeClass("d-none");
            elemToShow.removeClass("d-none");
        });

        $('.js-viewless').on('click', function (e) {
            e.preventDefault();
            var elemToHide = $(this).closest(".js-view-container").find("li.elem");
            $(this).addClass("d-none");
            $(this).parent("div").find(".js-viewall").removeClass("d-none");
            elemToHide.addClass("d-none");
        });
        /*$('.faccette__header').on('click', function (){
            var termList=$(this).find('faccette__field');
            termList.toggleClass("d-none");
        });*/
    }

    function viewFacet() {
        $('.intranet-search-grid__toggle-filters').on('click', function () {
            var searchResultBox = $('.intranet-search-grid__body');
            var colsearchResultBox = searchResultBox.find('.portlet-column-last');
            if (colsearchResultBox.hasClass('col-sm-8')) {
                colsearchResultBox.removeClass('col-sm-8');
                colsearchResultBox.addClass('col-sm-12');
            } else if (colsearchResultBox.hasClass('col-sm-12')) {
                colsearchResultBox.removeClass('col-sm-12');
                colsearchResultBox.addClass('col-sm-8');
            }
        });
    }

    function searchBarDeleteParameter() {
        let currentURL = window.location.href;
        if (currentURL.includes("search_facet") || currentURL.includes("_CustomFilterPortlet") || currentURL.includes("&mc") || currentURL.includes("?mc") || currentURL.includes("assetTagIds")) {

            var clearUrl = function (event, el) {
                event.preventDefault();
                let portletNamespace = el.data("namespace");
                let currentUrlSplitted = window.location.href.replace(window.location.search, '');
                let keyword = $(".8020-search-bar-keywords-input_" + portletNamespace).val();
                window.location.href = currentUrlSplitted + "?q=" + keyword;
            }

            $(".intranet-search-grid__submit").click(function (event) {
                clearUrl(event, $(this));
            });
            $(".intranet-search-grid__submit").on('keypress', function (event) {
                if (event.keyCode === 13) {
                    clearUrl(event, $(this));
                }
            });
        }
    }

    function viewAll() {
        $('.js-viewall').on('click', function () {
            $(this).addClass('d-none').next('.js-viewless').removeClass('d-none').closest(".js-view-container").find("li").removeClass("d-none");
        });
        $('.js-viewless').on('click', function () {
            var maxElem = $(this).closest(".js-view-container").data("maxelem") || 5;
            $(this).addClass('d-none').prev('.js-viewall').removeClass('d-none').closest(".js-view-container").find(maxElem >= 0 ? "li:gt(" + maxElem + ")" : "li").addClass("d-none");
        });
    }

    //
    // Dark Mode
    //

    function initDarkMode() {
        $('.switch-color-scheme').on('click', function () {

            let fd = new FormData();
            fd.append('userId', themeDisplay.getUserId());
            $.ajax({
                type: "POST",
                url: '/o/intranet8020/dark/mode/set-user-color-preference',
                data: fd,
                cache: false,
                dataType: "json",
                processData: false,
                contentType: false,
                async: true,
                success: function (data) {
                    if (data.results == 'ko') {
                        console.debug("Failed to change user color theme preferences.");
                    }
				let body = $('body');
				
				if (body.hasClass('dark-theme')) {
					body.removeClass('dark-theme');
					document.getElementById("intranet-logo-light-dark-mode").src=themeDisplay.getPathThemeImages()+"/assets/images/8020/logo-intranet.png";				
				} else {
					body.addClass('dark-theme');
					document.getElementById("intranet-logo-light-dark-mode").src=themeDisplay.getPathThemeImages()+"/assets/images/8020/logo-intranet-white.png";
				}
//                    window.location.reload();
                },
                error: function () {
                    console.error("error");
                }
            });

            
        });
    }

    //
    // Board
    //

    function initBoard() {
        $('[id^="edit-button-announcement-"]').on('click', function () {
            let idElement = $(this).attr('data-element');
            let urlboard = $(this).attr('data-href');

            $("#" + idElement).load(urlboard);

        });

        $('#add-announcement').on('click', function () {
            let idElement = $(this).attr('data-element');
            let urlboard = $(this).attr('data-href');

            $("#body-announcement-portlet").load(urlboard);

        });

        if ($('[id^="button-board-delete-"]')) {
            $('[id^="button-board-delete-"]').on("click", function () {
                let element = $(this);

                let resourcePrimKey = element.data("resource-prim-key");

                let button = $("#modal-reference-board-delete-" + resourcePrimKey);
                let deleteUrl = button.data("href");

                document.location.href = deleteUrl;
            });
        }
    }

    //
    // Acknowledgment
    //

    function initAcknowledgment() {
        initAckConfirmButton();
        initAckModalButton();
    }

    function initAckConfirmButton() {

        $('.modal-acknowledgment .btn-confirm-acknowledgment').off('click').on("click", function (e) {
            e.preventDefault();

            let contentId = $(this).attr("data-contentId");
            let className = $(this).attr("data-className");
            let userId = $(this).attr("data-userId");
            let version = $(this).attr("data-version");
            let groupId = $(this).attr("data-groupId");
            let companyId = $(this).attr("data-companyId");
            let entityGroupId = $(this).attr("data-entityGroupId");

            let url = "/o/intranet8020/acknowledgment/set-acknowledgment?className=" + className + "&contentId=" + contentId + "&userId=" + userId + "&version=" + version + "&entityGroupId=" + entityGroupId + "&groupId=" + groupId + "&companyId=" + companyId;

            $.ajax({
                url: url,
                method: "GET",
                dataType: "json",
                cache: false,
                async: false,
                success: function (data) {
                    location.reload(true);
                },
                error: function (data) {
                    console.error("errore");
                }
            });
        });
    }

    function initAckModalButton() {

        $('.btn-acknowledgment button.btn-acknowledgment__btn').off('click').on("click", function (e) {
            e.preventDefault();

            let contentId = $(this).attr("data-contentId");
            let className = $(this).attr("data-className");
            let userId = $(this).attr("data-userId");
            let entityGroupId = $(this).attr("data-entityGroupId");

            let ackModalId = $(this).attr("data-ackModalId");
            let ackModal = $("#" + ackModalId);
            let ackModalButton = $("#" + ackModalId + " .btn-confirm-acknowledgment");
            let ackModalText = $("#" + ackModalId + " .ack-modal-text");

            let url = "/o/intranet8020/acknowledgment/get-acknowledgment-modal?className=" + className + "&contentId=" + contentId + "&userId=" + userId;

            $.ajax({
                url: url,
                method: "GET",
                dataType: "json",
                cache: false,
                async: false,
                success: function (data) {
                    if (data.results == 'ok') {

                        ackModal.modal("show");
                        ackModalText.empty();
                        ackModalText.append(data.modalText);

                        if (data.isDocumentDownloadRequired) {
                            ackModalButton.addClass("d-none");
                        } else {
                            ackModalButton.removeClass("d-none");
                            ackModalButton.attr("data-className", className);
                            ackModalButton.attr("data-contentId", contentId);
                            ackModalButton.attr("data-version", data.version);
                            ackModalButton.attr("data-entityGroupId", entityGroupId);
                        }
                    }
                },
                error: function (data) {
                    console.error("errore");
                }
            });
        });
    }

    //
    //  Favourite
    //

    function initFavourite(cssSelector) {
        initFavouriteStars(cssSelector);
        initMyFavouriteHomePage(cssSelector);
        initMyFavouriteMyPage(cssSelector);
    }

    function initFavouriteStars(cssSelector) {

        $(cssSelector + " .favourite-star").off('click').each(function () {
            if ($(this).attr("id") !== "userFavouriteContactsFilter") {
                $(this).on("click", function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    let starElement = $(this);
                    let classPK = starElement.attr('data-classPK');
                    if (starElement.hasClass("active")) {
                        let favouriteId = starElement.attr('data-favouriteId');
                        removeFavorite(favouriteId, classPK, starElement);
                    } else {
                        let className = starElement.attr('data-className');
                        let contentType = starElement.attr('data-contentType');
                        let userId = starElement.attr('data-userId');
                        let companyId = starElement.attr('data-companyId');
                        addFavourite(className, classPK, contentType, userId, companyId);

                        // matomo
                        if (_paq) {
                            _paq.push(['trackEvent', 'Favourite', 'Add Favourite', starElement.data('mtm')]);
                        }
                    }

                    let favouriteFilterSelected = $('.favorite-filter__item--active');
                    if (favouriteFilterSelected.length === 0) {
                        let favouriteSelect = $('.box-favorite .box-favorite__select select');
                        favouriteFilterSelected = favouriteSelect.find('option:selected');
                    }
                    reloadMyFavourites(favouriteFilterSelected, cssSelector);
                });
            }
        });
    }

    function initMyFavouriteHomePage(cssSelector) {
        $(".box-favorite .box-favorite__select select").off('change').each(function () {
            $(this).on("change", function (e) {
                let favouriteFilterSelected = $(this).find('option:selected');
                reloadMyFavourites(favouriteFilterSelected, cssSelector);
            });
        });
    }

    function initMyFavouriteMyPage(cssSelector) {

        $('.favorite-filter__link').off('click').each(function () {
            $(this).on("click", function (e) {
                e.preventDefault();
                let favouriteFilterSelected = $(this).parent('.favorite-filter__item');
                $('.favorite-filter__item').removeClass('favorite-filter__item--active');
                favouriteFilterSelected.addClass('favorite-filter__item--active');

                reloadMyFavourites(favouriteFilterSelected, cssSelector);
            });
        });
    }

    function addFavourite(className, classPK, contentType, userId, companyId) {
        $.ajax({
            type: "GET",
            url: "/o/intranet8020/manager/favourite/add-favourite?className=" + className + "&classPK=" + classPK + "&userId=" + userId + "&companyId=" + companyId + "&contentType=" + contentType,
            cache: false,
            async: false,
            dataType: "json",
            success: function (data) {
                updateStarElements(classPK, data.favouriteId)
            },
            error: function (xhr, ajaxOptions, thrownError) {
                if (console) {
                    console.error(xhr.status);
                    console.error(thrownError);
                }
            }
        });
    }

    function removeFavorite(favouriteId, classPK, starElement) {
        $.ajax({
            type: "GET",
            url: "/o/intranet8020/manager/favourite/remove-favourite?favouriteId=" + favouriteId,
            cache: false,
            async: false,
            dataType: "json",
            success: function () {
                updateStarElements(classPK, "0")

                let removeWrapper = starElement.data('remove-wrapper');
                if (removeWrapper) {
                    starElement.parents('.wrap-card-favoritee').remove();
                }
            },
            error: function (xhr, thrownError) {
                if (console) {
                    console.error(xhr);
                    console.error(thrownError);
                }
            }
        });
    }

    function updateStarElements(classPK, favouriteId) {

        let starLabel = $(".lfr-favourite-star__label");
        starLabel.empty();

        $(".favourite-star").each(function () {
            if ($(this).attr("id") !== "userFavouriteContactsFilter") {
                let starElement = $(this);
                if (starElement.attr('data-classPK') === classPK) {
                    if (favouriteId !== "0") {
                        starElement.addClass('active');
                        starElement.attr("data-favouriteId", favouriteId);
                        starLabel.html("Rimuovi dai preferiti");
                    } else {
                        starElement.removeClass("active");
                        starElement.attr("data-favouriteId", "0");
                        starLabel.html("Salva tra i preferiti");
                    }
                }
            }
        });
    }

    function reloadMyFavourites(favouriteFilterSelected, cssSelector) {

        let url = favouriteFilterSelected.data('url');
        let target = $('#all-favorite-filter-box');
        if (target.length === 0) {
            target = $('#filter-recenti');
        }

        $.ajax({
            type: "POST",
            url: url,
            cache: false,
            async: false,
            dataType: "html",
            success: function (data) {
                //replace html
                target.empty();
                target.html(data);
                initFavouriteStars(cssSelector);
                initAcknowledgment();
            }
        });
    }

    //
    //  HappyBirthday
    //

    function initHappyBirthday() {
        $(".birthday-box-button").off('click').each(function () {
            $(this).on("click", function (e) {
                e.preventDefault();
                e.stopPropagation();

                let birthdayUserId = $(this).attr('data-birthdayUserId');

                let fd = new FormData();
                fd.append('message', '');
                fd.append('birthdayUserId', birthdayUserId);
                fd.append('companyId', themeDisplay.getCompanyId());
                fd.append('groupId', themeDisplay.getScopeGroupId());
                fd.append('userId', themeDisplay.getUserId());

                $.ajax({
                    type: 'POST',
                    url: '/o/intranet8020/birthday/add-happy-birthday',
                    data: fd,
                    dataType: "json",
                    cache: false,
                    async: false,
                    processData: false,
                    contentType: false,
                    success: (data) => {
                        $(this).addClass('d-none');
                        $(this).siblings(".birthday-box-done").addClass('active');
                    },
                    error: function (data) {
                        console.error("error doing happy birthday");
                        console.error(data);
                    }
                });
            });
        });
    }

    //
    // Others
    //

    if ($('.box-portlet .box-portlet__close').length > 0) {
        closePorlet();
    }

    function closePorlet() {
        $('.box-portlet .box-portlet__close').on('click', function () {
            $(this).parents('.box-portlet').remove();
        });
    }
}