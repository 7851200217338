import $ from 'jquery';

export default function boxDocumenti() {
  
  function init() {

    $('.box-document, .box-document__submenu button[data-target]').on('click', function(e) {
      const idModal = $(this).attr('data-target');
      
      $(idModal).modal('show');
    });
  }

  
  $( document ).ready(function() {
    if($('.box-document, .box-document__submenu button[data-target]').length > 0) {
      init();
    }
  });
}